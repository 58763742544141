import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import s from './description.scss'

interface DescriptionProps {
  event: wix.events.Event
}

export const Description = ({event}: DescriptionProps) => {
  const {experiments} = useExperiments()

  if (!event.description) {
    return null
  }

  return (
    <div
      className={classNames(s.description, {
        [s.overrideMargin]: experiments.enabled(ExperimentNames.WidgetSpacingUpdates),
      })}
      data-hook="ev-list-item-description"
    >
      {event.description}
    </div>
  )
}
