import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {TFunction, useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {SocialBar} from '../../../social-bar'
import s from './share.scss'

export interface ShareProps {
  t: TFunction
  event: wix.events.Event
}

export const Share = ({t, event}: ShareProps) => {
  const {experiments} = useExperiments()

  return (
    <div
      className={classNames(s.container, {
        [s.overrideMargin]: experiments.enabled(ExperimentNames.WidgetSpacingUpdates),
      })}
    >
      <SocialBar t={t} spacing={24} event={event} row />
    </div>
  )
}
