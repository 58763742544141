import {useStyles} from '@wix/tpa-settings/react'
import {
  ExperimentNames,
  LIST_LAYOUT_TYPE,
  getFormattedFullLocation,
  getFormattedLocation,
  isDateTbd,
  useDidUpdate,
} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useRef, useState} from 'react'
import {ExpandArrow} from '../../../../../../icons/ExpandArrow'
import stylesParams from '../../../../stylesParams'
import {useWidgetState} from '../../../hooks/state-provider'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {getRibbonData} from '../../../selectors/events'
import {isItemOpened, shouldShowCountdown} from '../../../selectors/layout'
import {isMembersVisible} from '../../../selectors/settings'
import classesV2 from '../../classes-v2.scss'
import {Countdown} from '../../countdown'
import {AdditionalInfoFullDateLocation} from '../../full-date-location'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {Date} from './date'
import {Description} from './description'
import s from './desktop-item.scss'
import {Image} from './image'
import {Members} from './members'
import {Share} from './share'

interface DesktopItemProps {
  event: ExtendedEvent
}

const TRANSITION_DURATION = 400

export const DesktopItem = ({event}: DesktopItemProps) => {
  const {t} = useTranslation()
  const {experiments} = useExperiments()
  const {isEditor} = useEnvironment()
  const openedFromEditor = useWidgetState(state => isItemOpened(state, event.id))

  const {get} = useStyles()
  const listCards = get(stylesParams.listLayoutType) === LIST_LAYOUT_TYPE.CARDS

  const [openedFromViewer, setOpenedFromViewer] = useState(false)
  const opened = openedFromViewer || openedFromEditor
  const expandableContainerRef = useRef<HTMLDivElement>(null)

  const showMembers = useWidgetState(state => isMembersVisible(state, event))
  const widgetPaddingSettingsEnabled = experiments.enabled(ExperimentNames.WidgetPaddingSettings)

  const toggleExpand = () => {
    setOpenedFromViewer(prev => !prev)
  }

  useDidUpdate(() => {
    let timeoutId: NodeJS.Timeout

    if (opened) {
      expandableContainerRef.current.style.visibility = 'visible'
      expandableContainerRef.current.style.height = `${expandableContainerRef.current?.scrollHeight}px`
      timeoutId = setTimeout(() => (expandableContainerRef.current.style.height = 'auto'), TRANSITION_DURATION)
    } else {
      if (isEditor) {
        expandableContainerRef.current.style.height = '0px'
      } else {
        requestAnimationFrame(() => {
          expandableContainerRef.current.style.visibility = 'visible'
          expandableContainerRef.current.style.height = `${expandableContainerRef.current?.scrollHeight}px`

          requestAnimationFrame(() => {
            expandableContainerRef.current.style.height = '0px'
            timeoutId = setTimeout(
              () => (expandableContainerRef.current.style.visibility = 'hidden'),
              TRANSITION_DURATION,
            )
          })
        })
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [opened])

  return (
    <li
      className={classNames(s.container, {
        [s.opened]: opened,
        [s.card]: listCards,
        [s.disableTransitions]: isEditor,
        [s.topBorder]: widgetPaddingSettingsEnabled,
        [s.hideBottomBorder]: !widgetPaddingSettingsEnabled,
      })}
      onClick={toggleExpand}
      data-hook={DH.listItem}
    >
      <div className={s.imageContainer}>
        <Image event={event} />
      </div>
      <div className={s.itemContent}>
        <CollapsedSection event={event} opened={opened} toggleExpand={toggleExpand} />
        <div className={s.additionalInfo} ref={expandableContainerRef} onClick={e => e.stopPropagation()}>
          <div className={s.infoPadding}>
            <AdditionalInfoFullDateLocation
              event={event}
              dateClassName={classesV2.hoverEventDate}
              locationClassName={classesV2.hoverEventLocation}
            />
            <Description event={event} />
            <Members event={event} visible={showMembers} />
            <Share t={t} event={event} />
          </div>
        </div>
      </div>
    </li>
  )
}

const CollapsedSection = ({
  event,
  opened,
  toggleExpand,
}: {
  event: ExtendedEvent
  opened: boolean
  toggleExpand: () => void
}) => {
  const {
    allBreakpoints: {isListCountdownEnabled, isListShortLocationVisible, isListFullLocationVisible},
  } = useVisibilityStyles()
  const showRibbon = useWidgetState(state => getRibbonData(state, event)).visible
  const scheduleTbd = isDateTbd(event)
  const showCountdown = shouldShowCountdown(event)

  return (
    <div className={s.collapsedSection}>
      <div className={s.collapsedInfo}>
        {showRibbon ? (
          <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />
        ) : null}
        {isListCountdownEnabled() && showCountdown && (
          <div className={s.countdown}>
            <Countdown event={event} />
          </div>
        )}
        <Date scheduleTbd={scheduleTbd} event={event} />
        <div className={s.titleLocationWrapper}>
          <a
            className={s.titleWrapper}
            aria-expanded={opened}
            role="button"
            tabIndex={0}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                toggleExpand()
              }
            }}
          >
            <span className={s.title}>{event.title}</span>
            <span className={s.expandArrow}>
              <ExpandArrow />
            </span>
          </a>
          <span className={s.divider} aria-hidden="true">
            &nbsp;/&nbsp;
          </span>
          <div className={classNames(s.location, s.locationVisibility)} data-hook="ev-list-item-location">
            {isListShortLocationVisible() && <span className={s.shortLocation}>{getFormattedLocation(event)}</span>}
            {isListFullLocationVisible() && <span className={s.fullLocation}>{getFormattedFullLocation(event)}</span>}
          </div>
        </div>
      </div>
      <RsvpButton event={event} minWidth={100} maxWidth={200} />
    </div>
  )
}
