import {useSettings} from '@wix/tpa-settings/react'
import React from 'react'
import settingsParams from '../../../settingsParams'
import {FeaturedList} from './featured-list/featured-list'
import List from './list'

export const ListLayout = () => {
  const {get} = useSettings()
  const featuredEvent = get(settingsParams.featuredEvent)

  return featuredEvent ? <FeaturedList /> : <List />
}
